<template>
  <section class="banner">
    <div v-if="$props.video === undefined" class="banner_container" :style="'background-image:url('+$props.background+');background-size:cover;text-align:'+$props.contentAlign">
      <h1 class="title"> {{$props.title}}</h1>
      <h4 class="subtitle mb-5"> {{$props.subtitle}}</h4>
      <div>
        <ButtonComponent v-if="button.type == 'simple'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonComponent>
        <ButtonMeetingComponent v-if="button.type == 'meeting'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonMeetingComponent>
      </div>
    </div>
    <div  id="videoDiv" v-if="$props.video != undefined">
      <video  id="video1" preload="" autoplay="" muted="" playsinline="" loop="">
        <source :src="$props.video" type="video/mp4">
      </video>
      <div id="videoMessage">
        <h1 class="title"> {{$props.title}}</h1>
        <h4 class="subtitle mb-5"> {{$props.subtitle}}</h4>
        <div class="container-fluid">
          <ButtonComponent class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue';
import ButtonMeetingComponent from "@/packages/ducksites.ui/components/buttons/ButtonMeetingComponent.vue";
export default {
  name: 'BannerProductComponent',
  components: {ButtonMeetingComponent, ButtonComponent},
  props: {
    title: String,
    subtitle: String,
    image: String,
    background: String,
    buttons: Array,
    video: String,
    contentAlign:{
      type: String,
      default: 'center'
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner_container{
    padding:2rem;
  }
  .title{
    margin-top:0.5em;
    font-size:3.5em;
    font-weight: 500;
    color:#ffffff;
    text-shadow: 2px 2px #222222;
    @media (max-width: 768px) {
      font-size:2.0em;
    }
  }
  .subtitle{
    font-size:2em;
    color: #f8f8f8;
    text-shadow: 1px 1px #444444;
    width: 40%;
    font-weight: 600;
  }
  #videoDiv {
    width: 100%; height: 400px; position: relative; overflow: hidden;
  }
  #video1 {
    margin: auto; display: block; width: 100%; height: auto;
    @media (max-width: 768px) {
      height: 50vh;
      object-fit: fill;
    }
  }
  #videoMessage {
    position: absolute; top: 0; left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content();
    font-weight: bold;
    margin-top: 1.2em;
  }
</style>
